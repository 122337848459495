@media (max-width: 768px) {
    .api-services {
        padding: 60px 0;
    }

    .api-services .container {
        padding: 0 20px;
    }

    .feature, .tier {
        flex-basis: 100%;
        max-width: 350px;
    }

    .api-services .glow-text, .pricing-title {
        font-size: 2.2rem;
    }
}

@media (min-width: 1200px) {
    .api-services .glow-text, .pricing-title {
        font-size: 3.2rem;
    }

    .feature h3, .tier h4 {
        font-size: 2rem;
    }

    .feature p, .tier li, .cta-section p {
        font-size: 1.2rem;
    }
}
.api-services {
    color: var(--text-color);
    padding: 100px 0;
    text-align: center;
    position: relative;
}

.api-services::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(212, 175, 55, 0.1) 0%, rgba(10, 10, 26, 0) 70%);
    pointer-events: none;
}

.api-services .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
}

.api-services .glow-text {
    font-size: 2.8rem;
    margin-bottom: 50px;
    color: var(--primary-color);
    text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

.section-description {
    font-size: 1.2rem;
    margin-bottom: 50px;
}

.api-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 60px;
}

.feature {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 30px;
    transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
    flex: 1;
    min-width: 250px;
    max-width: 350px;
}

.feature:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(212, 175, 55, 0.2);
}

.feature h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #fff;
}

.feature p {
    font-size: 1.1rem;
    color: #bbb;
}

.pricing-title {
    font-size: 2.4rem;
    margin-bottom: 40px;
    color: var(--primary-color);
    text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

.pricing-tiers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 60px;
}

.tier {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 30px;
    transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
    flex: 1;
    min-width: 300px;
    max-width: 350px;
}

.tier:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(212, 175, 55, 0.2);
}

.tier h4 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--primary-color);
}

.tier .price {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 1rem 0;
    color: #fff;
}

.tier ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
}

.tier li {
    margin-bottom: 10px;
    color: #bbb;
}

.cta-section {
    margin-top: 60px;
}

.cta-section h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--primary-color);
}

.cta-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #bbb;
}

.cta-button {
    padding: 15px 30px;
    font-size: 1.1rem;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all var(--transition-speed) ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    background-color: var(--primary-color);
    color: var(--background-color);
}

.cta-button:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    box-shadow: 0 0 20px rgba(212, 175, 55, 0.5);
}

.cta-button::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, var(--primary-color), #FFF, var(--primary-color));
    z-index: -1;
    filter: blur(5px);
    opacity: 0;
    transition: opacity var(--transition-speed) ease;
}

.cta-button:hover::before {
    opacity: 1;
}



.neon-border {
    border: 2px solid var(--primary-color);
    box-shadow: 0 0 10px rgba(212, 175, 55, 0.5), inset 0 0 10px rgba(212, 175, 55, 0.5);
}