/* Contribute styles */
.contribute-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 26, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
}

.contribute-content {
    background-color: var(--background-color);
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
    padding: 40px;
    box-shadow: 0 0 30px rgba(212, 175, 55, 0.3);
    animation: fadeIn 0.5s ease-out;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    background: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.close-button:hover {
    color: #FFD700;
    transform: rotate(90deg);
}

.contribute-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.hero-section {
    text-align: center;
    margin-bottom: 3rem;
}

.hero-section h1 {
    font-size: 3rem;
    color: var(--primary-color);
    text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
    margin-bottom: 1rem;
}

.hero-section h2 {
    font-size: 1.5rem;
    color: var(--text-color);
}

.section {
    margin-bottom: 4rem;
    position: relative;
}

.section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(212, 175, 55, 0.1) 0%, rgba(10, 10, 26, 0) 70%);
    pointer-events: none;
}

.section h2 {
    font-size: 2.5rem;
    color: var(--primary-color);
    text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
    margin-bottom: 1.5rem;
    text-align: center;
}

.two-column-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.text-column, .image-column {
    flex: 1;
    padding: 1rem;
    min-width: 300px;
}

.text-column p {
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 1.6;
}

.image-column img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(212, 175, 55, 0.3);
}

.crypto-addresses {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.crypto-item {
    text-align: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 20px;
    flex-basis: calc(33.333% - 20px);
    transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
    border: 2px solid rgba(212, 175, 55, 0.3); /* Added border */
}

.crypto-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(212, 175, 55, 0.2);
    border-color: var(--primary-color); /* Border color change on hover */
}

.crypto-item img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.crypto-item p {
    font-size: 0.9rem;
    color: var(--text-color);
    word-break: break-all;
}

.role-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.role-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    flex-basis: calc(25% - 20px);
    min-width: 200px;
    transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
}

.role-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(212, 175, 55, 0.2);
}

.role-card h3 {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 10px;
}

.role-card p {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 15px;
}

.role-card button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.role-card button:hover {
    background-color: #FFD700;
    transform: scale(1.05);
}

.how-to-get-involved ol {
    list-style-type: none;
    counter-reset: item;
    padding-left: 0;
}

.how-to-get-involved li {
    counter-increment: item;
    margin-bottom: 15px;
    padding-left: 40px;
    position: relative;
    font-size: 1.1rem;
    color: var(--text-color);
}

.how-to-get-involved li::before {
    content: counter(item);
    background: var(--primary-color);
    color: var(--background-color);
    font-weight: bold;
    font-size: 1rem;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.community-shoutouts {
    text-align: center;
}

.call-to-action {
    text-align: center;
    margin-top: 3rem;
}

.cta-button {
    padding: 15px 30px;
    font-size: 1.2rem;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all var(--transition-speed) ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
}

.cta-button::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, var(--primary-color), #FFF, var(--primary-color));
    z-index: -1;
    filter: blur(5px);
    opacity: 0;
    transition: opacity var(--transition-speed) ease;
}

.cta-button:hover::before {
    opacity: 1;
}

.cta-button:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    box-shadow: 0 0 20px rgba(212, 175, 55, 0.5);
}

/* Bottom close button styles */
.bottom-close-button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.bottom-close-button {
    padding: 15px 30px;
    font-size: 1.2rem;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all var(--transition-speed) ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
}

.bottom-close-button::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, var(--primary-color), #FFF, var(--primary-color));
    z-index: -1;
    filter: blur(5px);
    opacity: 0;
    transition: opacity var(--transition-speed) ease;
}

.bottom-close-button:hover::before {
    opacity: 1;
}

.bottom-close-button:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    box-shadow: 0 0 20px rgba(212, 175, 55, 0.5);
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
}

.role-card, .crypto-item {
    animation: float 5s ease-in-out infinite;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .crypto-item {
        flex-basis: calc(50% - 20px);
    }

    .role-card {
        flex-basis: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .contribute-content {
        padding: 20px;
    }

    .hero-section h1 {
        font-size: 2.5rem;
    }

    .hero-section h2 {
        font-size: 1.2rem;
    }

    .section h2 {
        font-size: 2rem;
    }

    .two-column-layout {
        flex-direction: column;
    }

    .text-column, .image-column {
        width: 100%;
    }

    .crypto-item, .role-card {
        flex-basis: 100%;
    }
}

/* Scrollbar */
.contribute-content::-webkit-scrollbar {
    width: 10px;
}

.contribute-content::-webkit-scrollbar-track {
    background: var(--background-color);
}

.contribute-content::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 5px;
}

.contribute-content::-webkit-scrollbar-thumb:hover {
    background: #FFD700;
}