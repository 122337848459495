/* Mobile Menu Styles */
@media screen and (max-width: 768px) {
  /* Hide the desktop menu */
  .main-nav {
    display: none !important;
  }

  /* Hamburger Icon */
  .hamburger-icon {
    display: block;
    cursor: pointer;
    padding: 10px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }

  .hamburger-icon span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: 0.4s;
  }

  /* Slide-out menu */
  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: #1a1a1a;
    transition: 0.4s;
    z-index: 999;
    overflow-y: auto;
  }

  .mobile-menu.open {
    right: 0;
  }

  .mobile-menu ul {
    list-style-type: none;
    padding: 0;
    margin-top: 60px;
  }

  .mobile-menu ul li {
    padding: 15px;
  }

  .mobile-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }

  /* Animation for hamburger icon */
  .hamburger-icon.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .hamburger-icon.open span:nth-child(2) {
    opacity: 0;
  }

  .hamburger-icon.open span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}

/* Desktop Menu Styles */
@media screen and (min-width: 769px) {
  .hamburger-icon,
  .mobile-menu {
    display: none !important;
  }

  .main-nav {
    display: block !important;
  }
}