/* BetaToS Popup Styles */
.beta-tos-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(10, 10, 26, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.beta-tos-modal {
    background: linear-gradient(135deg, #1a1a3a 0%, #0a0a1a 100%);
    border-radius: 10px;
    max-width: 850px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 0 20px rgba(212, 175, 55, 0.3);
}

.beta-tos-content {
    padding: 40px 60px;
}

.beta-tos-content h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: var(--primary-color, #D4AF37);
}

.beta-tos-content h2 {
    font-size: 1.8rem;
    margin-top: 25px;
    margin-bottom: 15px;
    color: var(--primary-color, #D4AF37);
}

.beta-tos-content .section {
    margin-bottom: 30px;
}

.beta-tos-content p {
    margin-bottom: 15px;
    color: var(--text-color, #e0e0e0);
    line-height: 1.6;
}

.beta-tos-content strong {
    color: var(--primary-color, #D4AF37);
}

.beta-tos-content .cta-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--primary-color, #D4AF37);
    color: var(--background-color, #0a0a1a);
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.beta-tos-content .cta-button:hover {
    background-color: #FFD700;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    color: var(--primary-color, #D4AF37);
    cursor: pointer;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #FFD700;
}

/* Scrollbar for BetaToS modal */
.beta-tos-modal::-webkit-scrollbar {
    width: 8px;
}

.beta-tos-modal::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.beta-tos-modal::-webkit-scrollbar-thumb {
    background: var(--primary-color, #D4AF37);
    border-radius: 4px;
}

.beta-tos-modal::-webkit-scrollbar-thumb:hover {
    background: #FFD700;
}