/* Reset and base styles */
:root {
    --primary-color: #D4AF37;
    --background-color: #0a0a1a;
    --text-color: #e0e0e0;
    --transition-speed: 0.3s;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Exo 2', Arial, sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
}

.full-width-container {
    width: 100%;
    padding: 0 40px;
}

/* Header and Hero Section */
.hero {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}

#bgVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, 
                rgba(10, 10, 26, 0.7) 0%, 
                rgba(10, 10, 26, 0.7) 60%, 
                rgba(10, 10, 26, 0.8) 70%,
                rgba(10, 10, 26, 0.9) 80%,
                rgba(10, 10, 26, 1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.logo {
    max-width: 400px;
    margin-bottom: 30px;
    filter: drop-shadow(0 0 10px rgba(212, 175, 55, 0.5));
}

h1, .glow-text {
    font-size: 3.5rem;
    margin-bottom: 20px;
    color: var(--primary-color);
    text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
    letter-spacing: 2px;
    font-weight: 700;
}

.hero p {
    font-size: 1.4rem;
    margin-bottom: 30px;
    color: #fff;
    max-width: 800px;
}

/* Navigation Menu */
.navigation-wrapper {
    position: sticky;
    top: 0;
    background-color: rgba(10, 10, 26, 0.9);
    z-index: 1000;
    border-bottom: 1px solid var(--primary-color);
}

.main-nav {
    padding: 15px 0;
}

.main-nav ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-nav li {
    margin: 0 20px;
}

.main-nav a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 500;
    transition: color var(--transition-speed) ease, text-shadow var(--transition-speed) ease;
}

.main-nav a:hover {
    color: var(--primary-color);
    text-shadow: 0 0 10px rgba(212, 175, 55, 0.7);
}

/* CTA Buttons */
.cta-button {
    padding: 15px 30px;
    font-size: 1.1rem;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all var(--transition-speed) ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
}

.cta-button::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, var(--primary-color), #FFF, var(--primary-color));
    z-index: -1;
    filter: blur(5px);
    opacity: 0;
    transition: opacity var(--transition-speed) ease;
}

.cta-button:hover::before {
    opacity: 1;
}

.cta-button.primary {
    background-color: var(--primary-color);
    color: var(--background-color);
}

.cta-button.primary:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    box-shadow: 0 0 20px rgba(212, 175, 55, 0.5);
}

.cta-button.secondary {
    background-color: transparent;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.cta-button.secondary:hover {
    background-color: var(--primary-color);
    color: var(--background-color);
    box-shadow: 0 0 20px rgba(212, 175, 55, 0.5);
}

/* Common section styles */
.section {
    padding: 100px 0;
    text-align: center;
    position: relative;
}

.section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(212, 175, 55, 0.1) 0%, rgba(10, 10, 26, 0) 70%);
    pointer-events: none;
}

.section h2 {
    font-size: 2.8rem;
    margin-bottom: 50px;
}

/* Features Section */
.features .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.feature {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 30px;
    transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
    flex: 1;
    min-width: 250px;
    max-width: 350px;
}

.feature:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(212, 175, 55, 0.2);
}

.icon {
    font-size: 3rem;
    margin-bottom: 20px;
    color: var(--primary-color);
    text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

.feature h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #fff;
}

.feature p {
    font-size: 1.1rem;
    color: #bbb;
}

/* Benefits Section */
.benefits ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 40px;
    width: 100%;
}

.benefits li {
    font-size: 1.2rem;
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    flex-basis: calc(50% - 15px);
    position: relative;
    transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
}

.benefits li:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(212, 175, 55, 0.2);
}

.benefits li::before {
    content: '✓';
    color: var(--primary-color);
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    text-shadow: 0 0 5px rgba(212, 175, 55, 0.5);
}

.callout, .highlight-box {
    background: linear-gradient(135deg, rgba(212, 175, 55, 0.8) 0%, rgba(255, 215, 0, 0.8) 100%);
    color: var(--background-color);
    padding: 30px;
    border-radius: 10px;
    margin: 0 auto 40px;
    box-shadow: 0 10px 30px rgba(212, 175, 55, 0.3);
    width: 100%;
    max-width: 800px;
}

/* Testimonials Section */
.testimonial-carousel {
    max-width: 800px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.testimonial {
    font-size: 1.2rem;
    line-height: 1.8;
    color: var(--text-color);
}

.testimonial-author {
    color: var(--primary-color);
    font-weight: bold;
    margin-top: 20px;
    display: block;
}

.review-summary {
    font-size: 1.4rem;
    margin-top: 50px;
    color: #fff;
}

.stars {
    color: var(--primary-color);
    font-size: 2rem;
    letter-spacing: 5px;
    text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

/* Roadmap Section */
.timeline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    position: relative;
    width: 100%;
    max-width: 1000px;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, var(--primary-color) 50%, rgba(212, 175, 55, 0) 100%);
    z-index: 1;
}

.milestone {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 30px;
    position: relative;
    z-index: 2;
    transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
    flex-basis: calc(33.33% - 20px);
    max-width: 300px;
}

.milestone:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(212, 175, 55, 0.2);
}

.milestone .icon {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.milestone h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #fff;
}

.milestone p {
    font-size: 1rem;
    color: #bbb;
}

/* Sign-Up Section */
#beta-signup-form {
    max-width: 500px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

#beta-signup-form input,
#beta-signup-form select {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 1rem;
}

#beta-signup-form input::placeholder,
#beta-signup-form select {
    color: rgba(255, 255, 255, 0.6);
}

#beta-signup-form select {
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 20px auto;
    padding-right: 30px;
}

#beta-signup-form select:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color);
}

#beta-signup-form select option {
    background-color: var(--background-color);
    color: #fff;
}

#beta-signup-form label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
}

#beta-signup-form input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    transition: all var(--transition-speed) ease;
}

#beta-signup-form input[type="checkbox"]:checked {
    background-color: var(--primary-color);
}

#beta-signup-form input[type="checkbox"]:checked::before {
    content: '✓';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--background-color);
    font-size: 14px;
    font-weight: bold;
}

#beta-signup-form input[type="checkbox"]:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(212, 175, 55, 0.5);
}

.tos-link {
    color: var(--primary-color);
    text-decoration: none;
    transition: color var(--transition-speed) ease, text-shadow var(--transition-speed) ease;
}

.tos-link:hover {
    color: #FFD700;
    text-shadow: 0 0 5px rgba(212, 175, 55, 0.5);
}

.privacy-note {
    font-size: 0.9rem;
    color: #bbb;
    margin-top: 30px;
}

/* Footer */
footer {
    background-color: rgba(10, 10, 26, 0.8);
    color: #fff;
    padding: 60px 0;
    text-align: center;
    position: relative;
}

footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, var(--primary-color) 50%, rgba(212, 175, 55, 0) 100%);
}

footer nav {
    margin-bottom: 30px;
}

footer nav a {
    color: #fff;
    text-decoration: none;
    margin: 0 15px;
    font-size: 1.1rem;
    transition: color var(--transition-speed) ease;
}

footer nav a:hover {
    color: var(--primary-color);
}

.social-icons {
    margin-bottom: 30px;
}

.social-icons a {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin: 0 10px;
    transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.social-icons a:hover {
    color: #fff;
    transform: translateY(-3px);
}

.newsletter-signup {
    max-width: 400px;
    margin: 0 auto 30px;
    display: flex;
}

.newsletter-signup input {
    flex-grow: 1;
    padding: 12px;
    border: none;
    border-radius: 5px 0 0 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 1rem;
}

.newsletter-signup button {
    padding: 12px 20px;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.newsletter-signup button:hover {
    background-color: #FFD700;
}

footer p {
    font-size: 0.9rem;
    color: #bbb;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .feature {
        flex-basis: calc(50% - 15px);
        max-width: none;
    }

    .timeline {
        flex-direction: column;
        align-items: center;
    }

    .milestone {
        max-width: 100%;
        margin-bottom: 30px;
    }

    .timeline::before {
        display: none;
    }

    .logo {
        max-width: 300px;
    }
}

@media (max-width: 768px) {
    .container, .full-width-container {
        padding: 0 20px;
    }

    h1, .glow-text {
        font-size: 2.5rem;
    }

    .hero p {
        font-size: 1.1rem;
    }

    .section {
        padding: 60px 0;
    }

    .feature {
        flex-basis: 100%;
        max-width: 350px;
    }

    .benefits li {
        flex-basis: 100%;
    }

    .logo {
        max-width: 250px;
    }

    .main-nav {
        display: none; /* Hide the main navigation on mobile */
    }

    .main-nav ul {
        flex-direction: column;
    }

    .main-nav li {
        margin: 10px 0;
    }

    .main-nav a {
        font-size: 1.2rem;
    }
}

@media (min-width: 769px) {
    .main-nav {
        display: block; /* Show the main navigation on desktop */
    }
}

@media (min-width: 1200px) {
    body {
        font-size: 18px;
    }

    h1, .glow-text {
        font-size: 4rem;
    }

    .section h2 {
        font-size: 3.2rem;
    }

    .feature h3 {
        font-size: 2rem;
    }

    .feature p,
    .benefits li,
    .testimonial,
    .milestone p {
        font-size: 1.2rem;
    }

    .main-nav a {
        font-size: 1.5rem;
    }
}

/* Animations */
@keyframes glow {
    0% {
        box-shadow: 0 0 5px rgba(212, 175, 55, 0.5);
    }
    50% {
        box-shadow: 0 0 20px rgba(212, 175, 55, 0.8);
    }
    100% {
        box-shadow: 0 0 5px rgba(212, 175, 55, 0.5);
    }
}

.cta-button {
    animation: glow 2s infinite;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.feature, .milestone {
    animation: float 5s ease-in-out infinite;
}

/* Additional Futuristic Elements */
.futuristic-line {
    height: 2px;
    background: linear-gradient(90deg, rgba(212, 175, 55, 0) 0%, var(--primary-color) 50%, rgba(212, 175, 55, 0) 100%);
    margin: 50px 0;
}

.neon-border {
    border: 2px solid var(--primary-color);
    box-shadow: 0 0 10px rgba(212, 175, 55, 0.5), inset 0 0 10px rgba(212, 175, 55, 0.5);
}

/* Particle effect for background */
#particles-js {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

/* Futuristic Scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: var(--background-color);
}

::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #FFD700;
}