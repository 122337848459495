.error-container {
  background-color: #fff3f3;
  border: 1px solid #ffcccb;
  border-radius: 4px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-title {
  color: #d32f2f;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.error-message {
  color: #333;
  font-size: 1em;
  margin-bottom: 15px;
}

.error-details {
  margin-top: 15px;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
}

.error-details summary {
  cursor: pointer;
  color: #666;
  font-weight: bold;
}

.error-details pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-top: 10px;
}

.retry-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.retry-button:hover {
  background-color: #45a049;
}

.retry-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
}

@media (prefers-color-scheme: dark) {
  .error-container {
    background-color: #2c2c2c;
    border-color: #4a4a4a;
  }

  .error-title {
    color: #ff6b6b;
  }

  .error-message {
    color: #e0e0e0;
  }

  .error-details {
    background-color: #3a3a3a;
  }

  .error-details summary {
    color: #b0b0b0;
  }

  .retry-button {
    background-color: #5cb85c;
  }

  .retry-button:hover {
    background-color: #4cae4c;
  }
}