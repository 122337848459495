.tos-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 26, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.tos-content {
  background-color: var(--background-color);
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  padding: 40px;
  box-shadow: 0 0 30px rgba(212, 175, 55, 0.3);
  animation: fadeIn 0.5s ease-out;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.close-button:hover {
  color: #FFD700;
  transform: rotate(90deg);
}

.tos-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.hero-section {
  text-align: center;
  margin-bottom: 3rem;
}

.hero-section h1 {
  font-size: 3rem;
  color: var(--primary-color);
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
  margin-bottom: 1rem;
}

.hero-section h2 {
  font-size: 1.5rem;
  color: var(--text-color);
}

.effective-date {
  text-align: center;
  margin-bottom: 2rem;
}

.effective-date h2 {
  font-size: 1.2rem;
  color: var(--text-color);
}

section {
  margin-bottom: 4rem;
  position: relative;
}

section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(212, 175, 55, 0.1) 0%, rgba(10, 10, 26, 0) 70%);
  pointer-events: none;
}

section h2 {
  font-size: 2.5rem;
  color: var(--primary-color);
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
  margin-bottom: 1.5rem;
}

p, ul {
  font-size: 1.1rem;
  color: var(--text-color);
  line-height: 1.6;
  margin-bottom: 1rem;
}

ul {
  padding-left: 2rem;
}

li {
  margin-bottom: 0.5rem;
}

strong {
  color: var(--primary-color);
}

.thank-you {
  text-align: center;
  font-style: italic;
  margin-top: 2rem;
}

/* Bottom close button styles */
.bottom-close-button-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.bottom-close-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.bottom-close-button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, var(--primary-color), #FFF, var(--primary-color));
  z-index: -1;
  filter: blur(5px);
  opacity: 0;
  transition: opacity var(--transition-speed) ease;
}

.bottom-close-button:hover::before {
  opacity: 1;
}

.bottom-close-button:hover {
  background-color: var(--background-color);
  color: var(--primary-color);
  box-shadow: 0 0 20px rgba(212, 175, 55, 0.5);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .tos-content {
    padding: 20px;
  }

  .hero-section h1 {
    font-size: 2.5rem;
  }

  .hero-section h2 {
    font-size: 1.2rem;
  }

  section h2 {
    font-size: 2rem;
  }
}

/* Scrollbar */
.tos-content::-webkit-scrollbar {
  width: 10px;
}

.tos-content::-webkit-scrollbar-track {
  background: var(--background-color);
}

.tos-content::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 5px;
}

.tos-content::-webkit-scrollbar-thumb:hover {
  background: #FFD700;
}