.error-reporter {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 20px;
  margin-top: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.error-reporter h3 {
  color: #333;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.error-reporter p {
  color: #666;
  margin-bottom: 15px;
}

.error-reporter .form-group {
  margin-bottom: 15px;
}

.error-reporter label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.error-reporter textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  min-height: 100px;
  font-family: inherit;
  font-size: 1em;
}

.error-reporter button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.error-reporter button:hover {
  background-color: #45a049;
}

.error-reporter button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
}

.error-reporter-success {
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  color: #3c763d;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .error-reporter {
    padding: 15px;
  }

  .error-reporter textarea {
    min-height: 80px;
  }

  .error-reporter button {
    width: 100%;
  }
}

@media (prefers-color-scheme: dark) {
  .error-reporter {
    background-color: #2c2c2c;
    border-color: #444;
  }

  .error-reporter h3 {
    color: #e0e0e0;
  }

  .error-reporter p,
  .error-reporter label {
    color: #b0b0b0;
  }

  .error-reporter textarea {
    background-color: #3a3a3a;
    border-color: #555;
    color: #e0e0e0;
  }

  .error-reporter button {
    background-color: #5cb85c;
  }

  .error-reporter button:hover {
    background-color: #4cae4c;
  }

  .error-reporter-success {
    background-color: #1e3a1e;
    border-color: #2c572c;
    color: #a3d7a3;
  }
}

/* Accessibility enhancements */
.error-reporter textarea:focus,
.error-reporter button:focus {
  outline: 2px solid #4caf50;
  outline-offset: 2px;
}

.error-reporter button:focus:not(:focus-visible) {
  outline: none;
}

.error-reporter button:focus-visible {
  outline: 2px solid #4caf50;
  outline-offset: 2px;
}